import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { parseJson } from '../../support'
import Layout from '../../layouts/layout'
import Seo from '../../components/seo'
import EasilyCalculate from '../../components/ForPersonalLoans/PersonalLoanPages/EasilyCalculate'
// import HowItWorksAndCompare from '../../components/UI/HowItWorksAndCompare'
import HeaderAndImg from '../../components/ForRatesAndFees/HeaderAndImg'
import CheckOutOurGreatRates from '../../components/ForRatesAndFees/CheckOutOurGreatRates'
import DefaultFees from '../../components/ForRatesAndFees/DefaultFees'
import QuestionsWithImages from '../../components/ForRatesAndFees/QuestionsWithImages'
import BgCircleGreen from '../../assets/images/svg/bg-circle-green.svg'
import LineSeparator from '../../assets/images/svg/line-separator.svg'

const RatesAndFees = ({ path }) => {
  const queriedStructuredDataConfigForRatesAndFees = useStaticQuery(graphql`
    query structuredDataConfigForRatesAndFeesQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "marketplace/rates-and-fees" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForRatesAndFees.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  return (
    <Layout hasGetYourRate={true}>
      <Seo
        title="Personal Loan Interest Rates & Fees"
        description="Looking for details on SocietyOne interest rates and fees? Find out what the low fixed rates are for our personal loans and get a tailored rate today."
        path={path}
        structuredData={structuredData}
      />
      <HeroWrapper>
        <HeaderAndImg />
        <ImageWrapper>
          <BgCircleGreen className="bg-circle" />
        </ImageWrapper>
      </HeroWrapper>
      <SeparatorWrapper>
        <LineSeparator className="line-separator" />
      </SeparatorWrapper>
      <CheckOutOurGreatRates />
      <EasilyCalculate header="Easily calculate your rate" />
      {/* <HowItWorksAndCompare hasHowItWorks={false} /> */}
      <Hr />
      <QuestionsWithImages />
      <DefaultFees />
    </Layout>
  )
}

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;
`
const ImageWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	max-width: 1020px;
	margin-right: auto;
	margin-left: auto;

  .bg-circle {
    position: absolute;
    left: auto;
    top: auto;
    right: -10px;
    bottom: 60px;
    width: 25vw;
    max-width: 324px;
  }
  @media screen and (max-width: 767px) {
    .bg-circle {
      bottom: -32px;
      right: 130px;
    }
  }
  @media screen and (max-width: 479px) {
    .bg-circle {
      width: 40vw;
      right: -10px;
    }
  }
`
const SeparatorWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .line-separator {
    width: 100%;
    height: 100%;
  }
`

const Hr = styled.hr`
  background-color: rgba(225 225 225);
  height: 1px;
  border: none;
  width: 100vw;
`

export default RatesAndFees
