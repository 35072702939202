import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import QuestionItem from './QuestionItem'

const QuestionsWithImages = () => {

  const data = useStaticQuery(graphql`
    query RatesAndFeesQuestionsWithImagesQuery {
      allContentfulComponentRatesAndFeesPageQuestionsAndImages(sort: { fields: [createdAt], order: ASC }) {
        nodes {
          header
          img {
            gatsbyImageData(width:500)
            title
          }
          questions {
            heading {
              childMarkdownRemark {
                html
              }
            }
            subheading {
              childMarkdownRemark {
                html
              }
            }
            icon {
              gatsbyImageData(width: 37)
              title
            }
          }
        }
      }
    }
  `)

  const items = data.allContentfulComponentRatesAndFeesPageQuestionsAndImages.nodes

  return (
    <ContentContainer>
      <QuestionsWithImagesWrapper>
        {items.map((item, index) => 
          <ItemWrapper key={index}>
            <h2>{item.header}</h2>
            <QuestionsWithImage>
              <Questions>
                {item.questions.map((item, index) => 
                  <QuestionItem item={item} key={index} />
                )}
              </Questions>
              <MainImg>
                <GatsbyImage image={item.img?.gatsbyImageData} alt={item.img.title} />
              </MainImg>
            </QuestionsWithImage>
          </ItemWrapper>
        )}
      </QuestionsWithImagesWrapper>
    </ContentContainer>
  )
}

const QuestionsWithImagesWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`
const QuestionsWithImage = styled.div`
	display: flex;	
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	@media screen and (max-width: 991px) {
		margin-top: 70px;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
	}
`
const ItemWrapper = styled.div`
  margin-bottom: 70px;
  h2 {
    margin-bottom: 30px;
  }

`
const Questions = styled.div`
  width: 50%;
	@media screen and (max-width: 768px) {
    width: 100%;
	}

`
const MainImg = styled.div`
	max-width: 393px;
	border-radius: 50%;
	overflow: hidden;
`

export default QuestionsWithImages