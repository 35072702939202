import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton';

const HeaderAndImg = () => {

  const handleClick = () => document.getElementById('our-rates').scrollIntoView()

	return (
		<ContentContainer maxWidth="1024px">
			<Main>
				<Header>
					<p className='goBackBtn'><Link to={`/personal-loans`}><span>Personal Loans</span></Link> {'>'} Rates & Fees</p>
					<h1 ><span className="greenHeader">Great rates</span>{' '}for the term of your loan.</h1>
					<p>All SocietyOne loans are <strong>fixed rate</strong>, so your monthly repayments never increase.</p>
					<div className="btnWrapper">
						<HomeButton
								height={BUTTON_SIZE.HEIGHT_L}
								padding={BUTTON_SIZE.PADDING_L}
								fontSize={BUTTON_SIZE.FONT_SIZE_L}
								background={BUTTON_COLOR.GREEN}
								backgroundHover={BUTTON_COLOR.GREEN_HOVER}
								color={BUTTON_COLOR.TEXT_COLOR_WHITE}
								text='View Our Rates'
								handleClick={handleClick}
							/>
					</div>
				</Header>
				<MainImg>
					<StaticImage src='../../assets/images/rates-fees.jpeg' alt="invest" placeholder='none'/>          
				</MainImg>
			</Main>
		</ContentContainer>
	)
}

const Main = styled.div`
	position: relative;
	z-index: 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 16px;
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		justify-items: center;
	};
`
const Header = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-width: 480px;
	.goBackBtn {
		margin: 0 0 20px 0;
		font-size: 14px;
		font-family: QuicksandBold;
		span {
			text-decoration: underline;	
		}
	}
	h1 {
		margin: 10px 0;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		}
		@media screen and (max-width: 768px) {
			text-align: center;
		};
	};
	.greenHeader {
		color: rgb(29 188 110);
	};
	>p {
		font-size: 20px;
		@media screen and (max-width: 768px) {
			text-align: center;
		};
	};
	.btnWrapper {
		padding: 10px 0;
		@media screen and (max-width: 768px) {
			margin:0 auto;
		};
	}
`

const MainImg = styled.div`
	max-width: 445px;
	overflow: hidden;
	img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: 991px) {
		max-width: 396px;
	};
	@media screen and (max-width: 768px) {
		margin: 30px 0;
	};
	@media screen and (max-width: 480px) {
		max-width: 100%;
	};
`

export default HeaderAndImg
