import React from 'react'
import styled from 'styled-components'

const UnsecuredTableComponent = ({items, has7Yr=true}) => {

	const getTd = (data) => {
		const tdArray = []
		Object.keys(data).forEach((key) => {
			tdArray.push(
				<td style={{
					fontSize: data[key].fontSize, 
					color: data[key].color, 
					background: data[key].background, 
					textAlign: 'left', 
					padding: '16px 20px', 
					border: '1px solid rgb(200 200 200)', 
					width: '266.67px',
					fontFamily: key === 'td1' ? 'Quicksand' : 'QuicksandBold',
					}}
					key={key}
				> 
					{data[key].content}
				</td>
			)
		})
		return tdArray
	}

	return (
		<Table>
			<tbody>
				<tr>
					<Th>Loan details</Th>
					<Th>2 - 3 year</Th>
					<Th>5 year</Th>
					{has7Yr && <Th>7 year</Th>}
				</tr>
				{/* first map to show the tr */}
				{items.map((item, index) => 
					<tr key={index}>
						{/* second map to show every td in each tr */}
						{getTd(item)}
					</tr>
				)}
			</tbody>
		</Table>
	)
}

const Table = styled.table`
	font-family: QuicksandSemiBold;
	max-width: 800px;
	margin: 0 auto;
	border: 1px solid rgb(200 200 200);
	border-collapse: collapse;
	@media screen and (max-width: 991px) {
		width: 100%;
	};
	@media screen and (max-width: 479px) {
		width: 100%;
		td {
			font-size: 14px !important;
		}
	};

`

const Th = styled.th`
	background: rgb(38 75 101);
	padding: 13px 24px;
	text-align: left;
	font-size: 18px;
	font-family: PoppinsBold;
	border: 1px solid rgb(200 200 200);
	color: rgb(255 255 255);
`

export default UnsecuredTableComponent
