import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'

const DefaultFees = () => {
  const data = useStaticQuery(graphql`
    query DefaultFees {
      contentfulComponentJsonTable(tableName: {eq: "DefaultFees"}) {
        data {
          td1 {
            content
            background
            color
            fontSize
          }
          td2 {
            content
            background
            color
            fontSize
          }
          td3 {
            content
            background
            color
            fontSize
          }
        }
      }
    }
  `)
  
  const items = data.contentfulComponentJsonTable.data

  const getTd = (data) => {
		const tdArray = []
		Object.keys(data).forEach((key) => {
			tdArray.push(
				<td style={{
					fontSize: data[key].fontSize, 
					color: data[key].color, 
					background: data[key].background, 
					textAlign: 'left', 
					padding: '16px 20px', 
					border: '1px solid rgb(200 200 200)', 
					fontFamily: key === 'td2' ? 'QuicksandBold' : 'Quicksand',
					}}
					key={key}
				> 
					{data[key].content}
				</td>
			)
		})
		return tdArray
	}

  return (
    <ContentContainer background={'var(--base-gray)'}>
      <DefaultFeesWrapper>
        <h2>Default Fees</h2>
        <p>Default fees apply if your repayment is late or we need to take action to recover the loan.</p>
        
        <div style={{overflowX: 'auto'}}>
          <Table>
            <tbody>
              <tr>
                <Th>Fee</Th>
                <Th>Amount</Th>
                <Th>Payable</Th>
              </tr>
              {items.map((item, index) => 
                <tr key={index}>
                  {getTd(item)}
                </tr>
              )}
            </tbody>
          </Table>
        </div>

      </DefaultFeesWrapper>
    </ContentContainer>
  )
}

const DefaultFeesWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 960px;
  h2 {
    margin: 0 0 30px 0;
  }
  >p {
    margin: 0 0 50px 0;
  }

`

const Table = styled.table`
	font-family: QuicksandSemiBold;
	margin: 0 auto;
	border: 1px solid rgb(200 200 200);
	border-collapse: collapse;
	@media screen and (max-width: 991px) {
		width: 100%;
	};
	@media screen and (max-width: 479px) {
		width: 100%;
		td {
			font-size: 12px !important;
      padding: 10px !important
		}
	};

`

const Th = styled.th`
	background: rgb(38 75 101);
	padding: 13px 24px;
	text-align: left;
	font-size: 18px;
	font-family: PoppinsBold;
	border: 1px solid rgb(200 200 200);
	color: rgb(255 255 255);
  @media screen and (max-width: 479px) {
    font-size: 12px !important;
    padding: 10px !important
	};
`

export default DefaultFees