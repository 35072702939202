import React, { useState } from 'react'
import styled from 'styled-components'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import UnsecuredT1 from './Tables/UnsecuredT1';
import UnsecuredT2 from './Tables/UnsecuredT2';
import UnsecuredT3 from './Tables/UnsecuredT3';
import UnsecuredT4 from './Tables/UnsecuredT4';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabPanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<h3>{children}</h3>
			)}
		</div>
	);
}

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabPanel-${index}`,
	};
}

const Unsecured = () => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// to customize material ui tab style
	const styles = {
    tab: {
			fontFamily: 'PoppinsBold',
			color: 'rgb(38 75 101)',
			background: 'white',
			textTransform: 'none',
			fontSize: '18px',
    },
	}

	return (
		<UnsecuredWrapper>
			<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered TabIndicatorProps={{style: {background:'rgb(38 75 101)'}}} style={{marginBottom: '20px'}}>
				<Tab label="Tier 1" {...a11yProps(0)} style={styles.tab} />
				<Tab label="Tier 2" {...a11yProps(1)} style={styles.tab} />
				<Tab label="Tier 3" {...a11yProps(2)} style={styles.tab} />
				<Tab label="Tier 4" {...a11yProps(3)} style={styles.tab} />
			</Tabs>

			<TabPanel value={value} index={0}>
				<UnsecuredT1 />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<UnsecuredT2 />
      </TabPanel>
			<TabPanel value={value} index={2}>
				<UnsecuredT3 />
      </TabPanel>
			<TabPanel value={value} index={3}>
				<UnsecuredT4 />
      </TabPanel>
		</UnsecuredWrapper>
	)
}

const UnsecuredWrapper = styled.div`
	width: 100%;
	margin: 0px;
	padding: 0px;
`

export default Unsecured
