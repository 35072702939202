import React, { useState } from 'react'
import styled from 'styled-components'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import addQuery from '../../../utils/addQuery';
import ContentContainer from '../../UI/ContentContainer'
import Unsecured from './Unsecured'
import Secured from './Secured'
import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../../../support'
import HomeButton from '../../UI/Buttons/HomeButton';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabPanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<h3>{children}</h3>
			)}
		</div>
	);
}

const a11yProps = (index) => {
	return {
	id: `simple-tab-${index}`,
	'aria-controls': `simple-tabPanel-${index}`,
	};
}

const CheckOutOurGreatRates = () => {

	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
	setValue(newValue);
	};

	// to customize material ui tab style
	const styles = {
    unsecuredTab: {
        fontFamily: 'PoppinsBold',
        color: value === 0 ? 'rgb(255 255 255)' : 'rgb(38 75 101)',
				background: value === 0 ? 'rgb(38 75 101)' : 'rgb(224 229 233)',
				textTransform: 'none',
				fontSize: '20px',
				borderRadius: '50px',
				margin: '0 15px',
				padding: '20px 32px'
    },
    securedTab: {
        fontFamily: 'PoppinsBold',
        color: value === 1 ? 'rgb(255 255 255)' : 'rgb(38 75 101)',
				background: value === 1 ? 'rgb(38 75 101)' : 'rgb(224 229 233)',
				textTransform: 'none',
				fontSize: '20px',
				borderRadius: '50px',
				margin: '0 15px',
				padding: '20px 32px'
    },
	}

	return (
		<ContentContainer padding="0 0 120px 0" mPadding="0 24px 120px" sPadding="0 24px 80px" xsPadding="0 20px 64px">
      <CheckRatesWrapper id="our-rates">
        <Header>Check out our great rates</Header>
        <UnsecuredAndSecuredWrapper>
					<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered TabIndicatorProps={{style: {background:'none'}}} style={{margin: '45px 0 40px 0'}}>
						<Tab label="Unsecured" {...a11yProps(0)} style={styles.unsecuredTab} />
						<Tab label="Secured" {...a11yProps(1)} style={styles.securedTab} />
					</Tabs>

					<TabPanel value={value} index={0}>
						<Unsecured />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Secured />
					</TabPanel>
        </UnsecuredAndSecuredWrapper>
				<a href={`#fine-print`} style={{textDecoration: 'underline', fontSize: 14, marginTop: 24}}>View fine print</a>
				<ButtonWrapper>
					<HomeButton
						height={BUTTON_SIZE.HEIGHT_L}
						padding={BUTTON_SIZE.PADDING_L}
						fontSize={BUTTON_SIZE.FONT_SIZE_L}
						background={BUTTON_COLOR.GREEN}
						backgroundHover={BUTTON_COLOR.GREEN_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_WHITE}
						text='Get My Rate'
						handleClick={() => addQuery(URL.QUOTE)}
					/>
				</ButtonWrapper>

      </CheckRatesWrapper>
		</ContentContainer>
	)
}

const CheckRatesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const UnsecuredAndSecuredWrapper = styled.div`
	width: 1024px;
	@media screen and (max-width: 991px) {
		width: 100%;
	};

`
const ButtonWrapper = styled.div`
	margin: 40px 0 0 0
`
const Header = styled.h2`
	text-align: center; 
	margin: 0 0 40px 0;
	@media screen and (max-width: 991px) {
		font-size: 32px; 
	};

`


export default CheckOutOurGreatRates