import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const QuestionItem = ({ item }) => {
  return (
    <ItemWrapper hasHeading={item.heading}>
      <div className="contents">
        {
          item.icon?.gatsbyImageData&&
          <GatsbyImage
            image={item.icon?.gatsbyImageData}
            alt={item.icon.title}
            className="imageWrapper"
          />
        }
        {item.heading && 
          <div
            dangerouslySetInnerHTML={{
              __html: item.heading.childMarkdownRemark.html,
            }}
          />
        }
      </div>
      <div
        className="additionalInfo"
        dangerouslySetInnerHTML={{
          __html: item.subheading.childMarkdownRemark.html,
        }}
      />
    </ItemWrapper>
  )
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.hasHeading ? 'column' : 'row'};
  align-items: flex-start;
  margin-bottom: 20px;
  .contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px
  }
  .additionalInfo {
    ${'' /* > * {
      margin-top: 0;
      
    } */}
    width: 100%
  }
  strong {
    margin: 0;
    font-family: Poppins;
  }
  .imageWrapper {
    margin-right: 20px;
  }

  p {
    margin: 5px;
  }
`

export default QuestionItem
